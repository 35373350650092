body {
  font-family: 'Open Sans', sans-serif;
  color: #445360;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-color {
  color: #1275fa;
}

.title-font {
  font-family: 'Roboto Slab', 'Helvetica', sans-serif;
}

.bgcolors {
  background: linear-gradient(
    2deg,
    rgba(53, 119, 242, 1),
    rgba(8, 240, 201, 1)
  );
}

/* Vertical Centering */
.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* HOME */
.panel-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #fff;
}

.text-grey {
  color: #ccc;
}

/* SLIDERS */
input[type='range'] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  margin-top: 10px;
  cursor: pointer;
  background: #ddd;
  border-radius: 5px;
}
input[type='range']::-webkit-slider-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 100;
}
input[type='range']:focus::-webkit-slider-runnable-track {
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 12px;
  margin-top: 10px;
  cursor: pointer;
  background: #ddd;
  border-radius: 5px;
}
input[type='range']::-moz-range-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 12px;
  margin-top: 10px;
  cursor: pointer;
  background: #ddd;
  border-radius: 5px;
}
input[type='range']::-ms-fill-lower {
}
input[type='range']::-ms-fill-upper {
}
input[type='range']::-ms-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type='range']:focus::-ms-fill-lower {
}
input[type='range']:focus::-ms-fill-upper {
}

input.init[type='range'],
input.init[type='range'],
input.init[type='range'] {
  animation: pulse 1s infinite;
}

/* Navigation */
#main-menu {
  font-family: 'Roboto Slab', 'Helvetica', sans-serif;
  border-bottom: 0;
  padding: 0;
  background: #fff;
}
#main-menu.affix {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
#main-menu a.navbar-brand {
  color: #333;
}

#main-menu .navbar-brand {
  font-size: 30px;
  /* line-height: 1; */
  font-weight: 300;
}
#main-menu .navbar-brand {
  float: left;
  height: 60px;
  padding: 15px;
}

#main-menu .nav > li > a,
.topbar .btn-link {
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
  padding: 20px;
  color: rgba(255, 255, 255, 0.9);
  border: none;
  background: transparent;
}
#main-menu .nav > li > a,
.topbar .btn-link {
  color: #333;
}

#main-menu .nav > li.active a,
#main-menu .nav > li.active:hover a,
#main-menu .nav > li:hover a {
  color: #fff;
  background: rgba(53, 119, 242, 1);
}

#module-name,
.navbar-brand a {
  color: #333;
}

.secondary-bar {
  border-left: 3px solid rgba(53, 119, 242);
  background: rgba(68, 70, 72, 0.1);
}

/* Menu Modal */
#menu-modal {
  background: rgba(71, 83, 101, 1);
}

/* Slide menu */
#slide-menu ul {
  background: #fff;
}
#slide-menu ul li > a,
#slide-menu ul li > span {
  color: #1275fa;
  border-left: 3px solid #fff;
}

#slide-menu ul li.active > a,
#slide-menu ul li.active > span,
#slide-menu ul li > a:hover,
#slide-menu ul li > span:hover {
  color: #444648;
  border-left: 3px solid #1275fa;
  background: #ececec;
}

#slide-menu ul .profile-info {
  color: #1275fa;
  padding: 20px 0 10px 0;
}
#slide-menu ul .close-menu {
  color: #fff;
}

/* Simulation Setup */
#shortlink {
  color: #1275fa;
}

.btn.coll-toggle,
.btn.coll-toggle:hover {
  color: #eee;
  background: #445360;
}

/* Bootstrap Buttons: Button */
/* Bootstrap Buttons: Link */
.btn-link {
  color: rgba(53, 119, 242, 1);
  border: none;
  background: transparent;
}
.btn-link.active,
.btn-link:hover,
.btn-link:focus,
.btn-link:focus:active,
.btn-link:active,
.btn-link.active:hover,
.btn-link:active:hover {
  border: none;
  background: transparent;
}
/* Bootstrap Buttons: Default */
.btn-default {
  color: #fff;
  border: 1px solid #37b5bd;
  background: #3eced7;
}
.btn-default.active,
.btn-default:hover,
.btn-default:focus,
.btn-default:focus:active,
.btn-default:active,
.btn-default.active:hover,
.btn-default:active:hover {
  color: #fff;
  border: 1px solid #37b5bd;
  background: #37b5bd;
}
/* Bootstrap Buttons: Primary */
.btn-primary {
  border: 1px solid rgba(53, 119, 242);
  background: rgba(53, 119, 242);
}
.btn-primary.active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus:active,
.btn-primary:active,
.btn-primary.active:hover,
.btn-primary:active:hover {
  border: 1px solid rgba(53, 119, 242);
  background: rgb(27, 107, 255);
}
/* Bootstrap Buttons: Success */
/* .btn-success{
  border: 1px solid #00bbe1;
  background: #00cdda;
}
.btn-success.active,
.btn-success:hover,
.btn-success:focus,
.btn-success:focus:active,
.btn-success:active,
.btn-success.active:hover,
.btn-success:active:hover{
  border: 1px solid #00bbe1;
  background: #00cdda;
} */
/* Bootstrap Buttons: Info */
.btn-info {
  border: 1px solid #1f71d6;
  background: #2687ff;
}
.btn-info.active,
.btn-info:hover,
.btn-info:focus,
.btn-info:focus:active,
.btn-info:active,
.btn-info.active:hover,
.btn-info:active:hover {
  border: 1px solid #1f71d6;
  background: #1f71d6;
}
/* Bootstrap Buttons: Warning */
.btn-warning {
  border: 1px solid #da7410;
  background: #ff8814;
}
.btn-warning.active,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:focus:active,
.btn-warning:active,
.btn-warning.active:hover,
.btn-warning:active:hover {
  border: 1px solid #da7410;
  background: #da7410;
}
/* Bootstrap Buttons: Danger */
.btn-danger {
  border: 1px solid #d84838;
  background: #fd5846;
}
.btn-danger.active,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:focus:active,
.btn-danger:active,
.btn-danger.active:hover,
.btn-danger:active:hover {
  border: 1px solid #d84838;
  background: #d84838;
}

/* Custom Labels */
.cat-marketing,
.btn-orange {
  background: #f39c12;
}
.cat-negotiations,
.btn-sky {
  background: rgba(53, 119, 242, 1);
}
.cat-management,
.btn-green {
  background: #2ecc71;
}

.btn-orange {
  border: 1px solid #f39c12;
}
.btn-orange:hover {
  background: #f39c12;
  opacity: 0.9;
}
.btn-sky {
  border: 1px solid rgba(53, 119, 242, 1);
}
.btn-sky:hover {
  background: rgba(53, 119, 242, 1);
  opacity: 0.9;
}
.btn-green {
  border: 1px solid #2ecc71;
}
.btn-green:hover {
  background: #2ecc71;
  opacity: 0.9;
}
.btn-white {
  border: 1px solid #eee;
  color: #666;
  background: #fff;
}
.btn-white:hover {
  opacity: 0.9;
  border: 1px solid #eee;
  background: #fff;
}

.btn-orange:hover,
.btn-sky:hover,
.btn-green:hover {
  color: #fff;
  border-color: transparent;
  opacity: 0.9;
}

.jumbotron {
  background: rgba(68, 70, 72, 0.1);
}

.nav-pills > li > a {
  color: #36b3bb;
  border: 1px solid #36b2ba;
  background-color: transparent;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #36b3bb;
}

/* Debrief Bar */
#inner-menu.nav-pills > li > a {
  border: none;
}
#inner-menu.nav-pills > li > a:hover {
  color: #1275fa;
}
#inner-menu.nav-pills > li.active > a,
#inner-menu.nav-pills > li.active > a:focus,
#inner-menu.nav-pills > li.active > a:hover {
  color: #1275fa;
  font-weight: 600;
  border: none;
  background: transparent;
}
